@tailwind base;
@tailwind components;
@tailwind utilities;

img {
	object-fit: cover;
	height: 100%;
	width: 100%;
	min-width:250px;
}

.hero {
	background-image: url('https://images.unsplash.com/photo-1557682250-33bd709cbe85?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1729&q=80');
	height:auto;
	background-size: cover;
	min-height: 400px;
}

.card.page {
	flex-direction: row;
	min-width: 400px;	
}

.facebook {
	background-color: #3b5998;
}

@media (max-width: 1080px) {
	.hero {
		min-height: 200px;
	}
	.navbar {
		flex-direction: column;
	}
	.welcome{
		display: none;
	}

	}

	


